import React from "react";
import { Link } from "gatsby";

// import Layout from "../components/layout"
// import SEO from "../components/seo"
// import VoucherAd from "../components/voucherAd";§
// import BackButton from "../components/backButton"
import SEO from "../components/seo";
import TopBar from "../components/Layout/TopBar";
import Navbar from "../components/Layout/Navbar";
import Footer from "../components/Layout/Footer";
import Seo from "../components/seo";
import Navigation from "../components/Layout/Navigation";
import DesktopMenu from "../components/Layout/Navigation/DesktopMenu";
import MenuControls from "../components/Layout/Navigation/MenuControls";
import MobileNavigation from "../components/Layout/Navigation/MobileNavigation";
import ReusableTiles from "../components/ReusableTiles";
import { css } from "@emotion/core";

// import "../scss/main.scss";

const TermsAndConditions = () => (
  // <Layout>
  <div>
    {/* <SEO title="DOTY Terms and Conditions" keywords={[`great`, `british`, `dogs`]} /> */}
    <SEO
      title="greatbritishdogs.co.uk | DOTY Terms and Conditions"
      keywords={[`great`, `british`, `dogs`]}
    />
    <TopBar></TopBar>
      <Navigation>
        <MenuControls />
        <MobileNavigation></MobileNavigation>
      </Navigation>
      <DesktopMenu />

    {/* <BackButton page="form" link="share-your-story"></BackButton> */}
    <div className="py-12 prose max-w-2xl mx-auto">
      <div className="container text-lightCopy">
      <div className="text-center">
              <div className="font-bench  text-white text-xl mx-auto bg-article px-4 py-1 uppercase leading-none inline-block rounded-lg my-3">
                Article
              </div>
            </div>
        <div className="form-title">
          <h1 css={css`
                color: #ae456c !important; margin: 0.5em auto 0.5em auto !important; font-size: 2rem !important; text-align: center !important; padding: 0 !important;
                `} className="font-slab">Terms and Conditions</h1>

          <h2 className="font-sans font-bold text-xl text-primary" css={css`
                margin-bottom: 1em !important; margin-top: 1em !important; font-size: 1.1em !important;`}>
            {" "}
            Great British Dogs DOTY 2024 Public Voting
          </h2>
        </div>

        <p className="my-4">
        The Promoter of the competition is Immediate Media Company London Limited.
        </p>
        <p className="my-4">
        Voting is open to all residents of the UK, including the Channel Islands, aged 18 years or older, except the Promoter’s employees or their direct family members, or anyone connected with the competition or the administration of the competition.
        </p>
        <p className="my-4">
        The public voting will run from 2 December 2024 until 31 December 2024, 23:59pm. Any votes cast after the closing date will not be considered. By casting your vote, you agree to be bound by these terms and conditions.
        </p>
        <h2 className="font-bold font-sans text-xl text-primary ">How to vote</h2>
        <p className="my-4">
        To cast your vote, complete and submit the form on www.greatbritishdogs.co.uk (the “Website”), including your name and email. There is a limit of one (1) vote per person and entries on behalf of another person will not be accepted.
        </p>
        <h2 className="font-bold font-sans text-xl text-primary" css={css`margin-top: 1.2em; !important;`}>The Prize</h2>
        <p className="my-4">One winner will receive a £100 Amazon voucher.</p>
        <p className="my-4">
        There is no cash alternative and the prize is not transferable. Prizes must be taken as stated and cannot be deferred. The Promoter reserves the right to substitute the prize with one of the same or greater value.
        </p>
        <p className="my-4">
        The winner will be notified within 28 days of the close of the competition by email. If the winner cannot be contacted, or fails to respond within 28 days of such notification being sent, the Promoter reserves the right to offer the prize to another eligible voter.
        </p>
        <p className="my-4">
        The Promoter may share the winner’s details with the prize provider for the purpose of fulfilling the prize.
        </p>
        <p className="my-4">
        The Promoter will process your personal details (and the personal details relating to any other individual(s) featured in your entry) in accordance with the {" "}<a className="font-bold text-primary" href="https://policies.immediate.co.uk/privacy/">Immediate Privacy Policy</a>{" "}.
        </p>
        <p className="my-4">The Promoter may share certain information relating to you with Nestlé Purina Petcare UK&I, including information required for marketing purposes where you have indicated you are happy to hear from them. Nestle Purina will process this data in accordance with their {" "}<a className="font-bold text-primary" href="https://www.purina.co.uk/privacy">Privacy Policy</a>{" "}.</p>
        <h2 className="font-bold font-sans text-xl text-primary" css={css`margin-top: 1.2em; !important;`}>
        General
        </h2>
        <p className="my-4">The Promoter reserves the right to amend these terms and conditions or to cancel, alter or amend the competition at any stage, if deemed necessary in its opinion, or if circumstances arise outside of its control.</p>
        <p className="my-4">The Promoter does not accept any responsibility for lost or fraudulent entries.</p>
        <p className="my-4">The Promoter excludes liability to the full extent permitted by law for any loss, damage or injury occurring to the participant arising from his or her entry into the promotion or occurring to the winner arising from his or her acceptance of a prize.</p>
        <p className="my-4">These terms and conditions shall be governed by English law and the parties submit to the exclusive jurisdiction of the course of England and Wales.</p><br />
      </div>
    </div>
    <div className="bg-pale-100">
        <div className="container" css={css`padding-right:0; padding-left:0;`}>
          <ReusableTiles />
        </div>
      </div>
    <Footer></Footer>
  </div>

  // </Layout>
);

export default TermsAndConditions;
